body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Charlie Display",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #3EB9DE !important;
}

.bg-mealee-menu {
    background-color: transparent;
    font-size: 18px;
    padding-bottom: 0 !important;
    font-weight: bold;
}

.dropdown-menu.show {
  background-color: #E5EFF5 !important;
}

.dropdown-divider {
  color: #FFFFFF !important;
  background: #FFFFFF !important;
  border-top: 1px solid #d3d3d3 !important;
}

.logo {
    margin-left: 23px !important;
}

.tooltip-inner {
  background-color: #d4af37 !important;
}
.tooltip.bs-tooltip-right .arrow:before {
    border-right-color: #d4af37 !important;
}
.tooltip.bs-tooltip-left .arrow:before {
    border-left-color: #d4af37 !important;
}
.tooltip.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #d4af37 !important;
}
.tooltip.bs-tooltip-top .arrow:before {
    border-top-color: #d4af37 !important;
}

.bg-mealee-light {
    background-color: #E5EFF5 !important;
}

.mealee-bucket {
    border-radius: 6px !important;
    background-color: #E5EFF5 !important;
    margin-bottom: 10px !important;
    min-width: 150px !important;
    max-width: 300px !important;
}

.mealee-bucket-body {
    padding: 8px 8px 4px 8px !important;
}

.mealee-bucket-header {
    color: #172B4D !important;
    font-family: "Charlie Display",sans-serif;
    font-size: 15px;
    padding: 8px 0px 0px 16px !important;
    border-bottom: 0 !important;
    background-color: #E5EFF5 !important;
}

.mealee-bucket-recipe {
    padding: 10px 20px 10px 20px !important;
    margin-bottom: 4px !important;
    border-radius: 4px !important;
}

.mealee-bucket-recipe-body {
    padding: 8px !important;
}

.mealee-recipe-selector-item {
    padding: 8px 50px 8px 8px !important;
    background-color: #FFFFFF !important;
    border-right: 0 !important;
    border-top: 0 !important;
    border-bottom: 1px solid;
    border-color: #D1EdF2 !important;
    height: 100% !important; 
}

.mealee-recipe-selector-item-highlighted {
    padding: 8px 50px 8px 8px !important;
    background-color: #d0f0c0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
    border-bottom: 1px solid;
    border-color: #D1EdF2 !important;
    height: 100% !important; 
}

.mealee-recipe-selector-label {
    border: 1px solid #dee2e6 !important;
    font-size: small;
    text-transform: uppercase;
    padding: 3px 6px 3px 6px !important;
}

.mealee-bucker-recipe-label {
    border: 1px solid #dee2e6 !important;
    font-size: small;
    text-transform: uppercase;
    padding: 3px 6px 3px 6px !important;
}

.btn-mealee {
    background-color: #E5EFF5 !important;
    font-family: "Charlie Display",sans-serif;
}

.mealee-recipe-selector-list {
    border-radius: 4px !important;
    scrollbar-color: #7f7f7f white !important;
    scrollbar-width: thin !important;
}

.mealee-settings-dialog {
    width: 800px !important;
    height: 90vh !important;
    max-width: none !important;
}

.mealee-settings-dialog .modal-body {
    max-height: calc(100% - (140px));
}

.mealee-settings-dialog .modal-content {
    height: 100% !important;
}

.mealee-settings-dialog .tab-content {
    height: calc(100% - (40px)) !important;
}

.mealee-settings-dialog .tab-content>.active {
    display: inline-flex;
    height: 100%;
    width: 100%
  }
  
  .mealee-settings-dialog  .tab-content>.active>.row {
    display: contents
  }
  
  .mealee-settings-dialog   .tab-content>.active>.row>div {
    padding-left: 0;
    width: 100%;
    display: contents;
  }
  
  .mealee-settings-dialog   .tab-content>.active>.row>div>.w-100 {
    width: 99% !important
  }

.tableFixHead { 
    overflow-y: auto; 
    max-height: calc(100% - (20px));
}

.tableFixHead table { 
    border: 0px !important;
}

.tableFixHead td { 
    border: 0px !important; 
}

.tableFixHead thead th { 
    position: sticky; 
    top: 0; 
    background-color: #E5EFF5;
    border-top: 0;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4) !important;
}

.form-control.mealee-table-edit {
    border-color: #00000010;
}

.popover.recipe-quick-view {
  min-width: 500px;
  box-shadow: 0px 0px 2px 2px #296d9860 !important;
}

.recipe-image {
    display: block;
    max-width:80vw;
    max-height:80vh;
    width: auto;
    height: auto;
    border: 4px solid black;
    z-index: 9999 !important;
  }

.popover.quick-info {
    min-width: 400px;
    box-shadow: 0px 0px 2px 2px #296d9860 !important;
}
 
.CodeMirror {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  height: 200px !important;
}

.CodeMirror-lint-tooltip {
  z-index: 9999 !important;
}

.CodeMirror pre.CodeMirror-placeholder {
  color: #636c72 !important;
}

.CodeMirror-hints {
  z-index: 9999 !important;
}

.hover .hover-on,
.hover:hover .hover-off {
  display: none;
}

.hover:hover .hover-on {
  display: inline;
}
